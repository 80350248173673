import styled from 'styled-components';

import { Table } from 'ui/elements/Table/styles'
import { Heading } from 'ui/elements/Heading/styles'
import { breakpoint } from 'ui/elements/ThemeProvider/utils'

import {
  MeetingLayout,
  fillContainer as MeetingLayoutFillContainer,
  fillPage as MeetingLayoutFillPage
} from './MeetingLayout/styles';


const PaddingVertical = 20;
const PaddingHorizontal = 0;

export const Section = styled.section`
  position: relative;
  background: ${(props) => props.background && props.theme.palette.paperAlpha};
  padding: ${(props) => props.compact ? '0' : `${PaddingVertical}px 0px`};
  
  ${breakpoint.md`
    padding: ${(props) => props.compact ? '0' : `${PaddingVertical}px ${PaddingHorizontal}px`};
  `};
  
  ${(props) => props.fillPage && `
    width: 100vw;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  `};

  > {  
    ${Heading} {
      margin-bottom: 30px;
    }
  }
  
  ${Table} {
    width: auto;
    
    margin: 0 ${(props) => (!props.compact) && `-${PaddingHorizontal}px`};
  }
  
  ${MeetingLayout} & {
    ${(props) => props.fillContainer && MeetingLayoutFillContainer};
    ${(props) => props.fillPage && MeetingLayoutFillPage};
  }
  
  & + & {
    margin-top: 40px;
  }
`;

export const InlineList = styled.div`
  display: flex;
  flex-wrap: wrap;
  list-style: none;

  > * {
    margin: 5px 10px 5px 0;
  }
`;
