import styled, { css } from 'styled-components';

import { breakpoint } from 'ui/elements/ThemeProvider/utils'
import { Box } from 'ui/elements/Icon/styles'

export const Sidebar = styled.div`
  color: ${(props) => props.theme.palette[props.color]};
  
  > * {
    margin-bottom: 20px;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: flex-start;
  
  ${Box} {
    margin-right: 15px;
    flex: 0 0 30%;
  }
`;

/* Sidebar width in px */
export const fillContainer = css`
  ${breakpoint.md`
    margin-left: -192px;
  `}

  ${breakpoint.lg`
    margin-left: -248px;
  `}
    
  ${breakpoint.xl`
    margin-left: -195px;
  `}
`;

export const fillPage = css`
  ${breakpoint.md`
    margin-left: calc(-100vw / 2 + 768px / 2 - 192px - 15px);
    margin-right: calc(-100vw / 2 + 768px / 2 - 15px);
    left: 0;
    right: 0;
  `}

  ${breakpoint.lg`
    margin-left: calc(-100vw / 2 + 992px / 2 - 248px - 15px);
    margin-right: calc(-100vw / 2 + 992px / 2 - 15px);
  `}
    
  ${breakpoint.xl`
    margin-left: calc(-100vw / 2 + 1170px / 2 - 195px - 15px);
    margin-right: calc(-100vw / 2 + 1170px / 2 - 15px);
  `}
`;

export const Main = styled.main`
  margin-bottom: 30px;
  
  > *:first-child:only-child {
    min-height: ${(props) => props.$sidebarSize.height}px;
  }
`;

export const MeetingLayout = styled.div`
  margin: 50px 0 0;
`;

