import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types';
import _map from 'lodash/map';
import _filter from 'lodash/filter';

import { Section } from 'components/Layout/styles';

import Background from './Background';
import Clean from './Clean';

import { Widget } from '../styles';
import { Carousel } from './styles';
import { graphql } from 'gatsby'

const Testimonials = ({
  testimonials,
  variant,
}) => {
  const [expanded, setExpanded] = useState(-1);
  const Component = variant === 'background' ? Background : Clean;

  const responsive = {
    md: { items: 1 },
    lg: { items: 1 },
    xl: { items: 1 },
  }

  const handleBeforeChange = () => {
    setExpanded(-1);
  }

  const handleOnToggle = (i) => {
    setExpanded(expanded === -1 ? i : -1);
  }

  const renderTestimonials = useMemo(() => {
    return _filter(testimonials, (testimonial) => {
      return !testimonial.hidden
    })
  }, [testimonials])

  return (
    <div>
      <Widget>
        <Section fillPage>
          <Carousel
            $variant={variant}
            responsive={responsive}
            beforeChange={handleBeforeChange}
          >
            {
              _map(renderTestimonials, (testimonial, i) => (
                <Component
                  expanded={expanded === i}
                  onToggle={() => handleOnToggle(i)}
                  key={testimonial.id}
                  testimonial={testimonial}
                  variant={variant}
                />
              ))
            }
          </Carousel>
        </Section>
      </Widget>
    </div>
  );
}

Testimonials.defaultProps = {
  variant: 'clean',
};

Testimonials.propTypes = {
  variant: PropTypes.oneOf(['clean', 'background']),
}

export default Testimonials;

export const query = graphql`
    fragment BaseTestimonial on SanityTestimonial {
      id
      previewText {
        en
        de
        _type
      }
      text {
        en
        de
        _type
      }
      name
      position
    }
    
    fragment TestimonialWithBackground on SanityTestimonial {
      ...BaseTestimonial
      image {
        gatsbyImageData(fit: FILLMAX, placeholder: DOMINANT_COLOR)
      }
    }
    
    fragment TestimonialClean on SanityTestimonial {
      ...BaseTestimonial
      image {
        gatsbyImageData(
          width: 555
          height: 370
          layout: CONSTRAINED
          placeholder: DOMINANT_COLOR
        )
      }
    }
`
