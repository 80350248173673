import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Spring } from 'react-spring/renderprops';

import Image from '../Image';
import * as S from './styles';

const Clean = ({
  testimonial,
  onToggle,
  expanded,
}) => {
  const { t } = useTranslation('common');
  const toggleLabel = expanded ? t('showLess') : t('readMore');
  const image = testimonial?.image

  return (
    <Container component={S.TestimonialCleanContainer}>
      <Row>
        <Col xs={12}>
          <S.ShortQuote level={1} color='primary'>{testimonial.previewText}</S.ShortQuote>
          <S.ShortQuoteCite>{testimonial.name}, {testimonial.position}</S.ShortQuoteCite>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <S.Toggle onClick={onToggle}>
            {toggleLabel}
          </S.Toggle>
        </Col>
      </Row>

      <Spring from={{ height: 0 }} to={{ height: expanded ? 'auto' : 0 }}>
        {({ height }) => (
          <S.ExpandedTestimonial style={{ height, overflow: 'hidden' }}>
            <Row align='center'>
              <Col xs={12} md={6}>
                <blockquote>{testimonial.text}</blockquote>
                <cite>{testimonial.name}, {testimonial.position}</cite>
              </Col>
              <Col xs={12} md={6}>
                {
                  image && (
                    <Image
                      {...image}
                      width={555}
                      height={370}
                      layout="constrained"
                      alt={testimonial.name}
                    />
                  )
                }
              </Col>
            </Row>
          </S.ExpandedTestimonial>
        )}
      </Spring>
    </Container>
  );
};

export default Clean;
