import React from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Spring } from 'react-spring/renderprops'

import * as S from './styles'
import Image from '../Image'

const Background = ({
  testimonial,
  onToggle,
  expanded,
}) => {
  const { t } = useTranslation('common');
  const toggleLabel = expanded ? t('showLess') : t('readMore');
  const image = testimonial?.image;

  return (
    <S.BackgroundWrapper>
      <S.BackgroundContainer>
        <Image
          {...image}
          style={{ objectFit: 'cover' }}
          alt={testimonial.name}
        />
      </S.BackgroundContainer>
      <Container>
        <Row>
          <Col xs={12} lg={6} component={S.BackgroundTestimonial}>
            <div>
              <S.ShortQuote level={1}>
                {testimonial.previewText}
              </S.ShortQuote>
              <S.ShortQuoteCite>{testimonial.name}, {testimonial.position}</S.ShortQuoteCite>
            </div>

            <S.Toggle  onClick={onToggle}>
              {toggleLabel}
            </S.Toggle>

            <Spring from={{ height: 0 }} to={{ height: expanded ? 'auto' : 0 }}>
              {({ height }) => (
                <S.ExpandedTestimonial style={{ height, overflow: 'hidden' }}>
                  <blockquote>{testimonial.text}</blockquote>
                  <cite>{testimonial.name}, {testimonial.position}</cite>
                </S.ExpandedTestimonial>
              )}
            </Spring>
          </Col>
        </Row>
      </Container>
    </S.BackgroundWrapper>
  )
};

export default Background;
