import styled from 'styled-components';
import UICarousel from 'ui/elements/Carousel';
import { Heading } from 'ui/elements/Heading/styles'
import { animated } from 'react-spring';
import { Styles as ImageStyles } from '../Image'

export const Carousel = styled(UICarousel)`
  ${(props) => props.$variant === 'clean' && `
    padding: 50px 0;
    background: ${props.theme.palette.paperAlpha};
  `};
  position: relative;

  > .react-multi-carousel-track {
    align-items: center;
    margin: 0;
    padding: 0;
  }
  
  .react-multi-carousel-item {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const ShortQuote = styled(Heading).attrs({
  as: "blockquote"
})`
  &:before {
    content: '\\201C';
  }
  
  &:after {
    content: '\\201D';
  }
`;

export const ShortQuoteCite = styled.cite`
  font-weight: bold;
  font-style: normal;
`

export const ExpandedTestimonial = styled(animated.div)`  
  blockquote {
    margin: 2rem 0;
    font-weight: 700;
    
    &:before {
      content: '\\201C';
    }
    
    &:after {
      content: '\\201D';
    }
  }
  
  cite {
    font-size: 1.4rem;
    font-style: normal;
  }
`;

export const Toggle = styled.span`
  font-weight: 700;
  font-size: 1.3rem;
  color: ${(props) => props.theme.palette.primary};
  cursor: pointer;
  display: block;
  text-decoration: underline;
`;

export const BackgroundWrapper = styled.div`
  padding: 50px 0;
  position: relative;
`;

export const BackgroundContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  
  ${ImageStyles.ImageWrap},
  ${ImageStyles.Image} {
    width: 100%;
    height: 100%;
  }
`;

export const BackgroundTestimonial = styled.div`
  background: rgba(255, 255, 255, .7);
  padding-top: ${(props) => props.theme.utils.grid.gutterWidth/2}px;
  padding-bottom: ${(props) => props.theme.utils.grid.gutterWidth/2}px;
`;


export const TestimonialCleanContainer = styled.div`
  ${ExpandedTestimonial} {
    color: ${(props) => props.theme.palette.black};
  }
`;
