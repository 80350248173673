import styled from 'styled-components';
import { breakpoint } from '../ThemeProvider';

const PaddingVertical = 8;
const PaddingHorizontal = 30;

const templateColumns = (props) => {
  const initial = props.equalColumns ? '1fr' : 'auto';
  return props.columnsDimension.map((d) => d || initial).join(' ');
}

export const Table = styled.div`
  width: 100%;
  line-height: 20px;
  text-align: left;
  background: rgb(242, 242, 242);
  display: grid;
  color: ${(props) => props.theme.palette[props.color]};
  grid-template-columns: 1fr;

  ${breakpoint.sm`
    grid-template-columns: ${(props) => templateColumns(props)};
  `}
`;

export const TableHeading = styled.span`
  padding: ${PaddingVertical}px ${PaddingHorizontal}px;
  font-weight: 700;
  color: ${(props) => props.theme.palette.typography};
  font-size: 1.4rem;
  line-height: 30px;
  display: none;
  
  ${breakpoint.sm`
    display: block;
  `}
`;

export const Cell = styled.span`
  display: block;
  padding: ${PaddingVertical}px ${PaddingHorizontal}px;
    
  background: ${(props) => (props.isRowOdd && props.zebra) ? 'rgb(229, 229, 229)' : 'rgb(242, 242, 242)'};
`;


